.counter {
  display: flex;
  flex-direction: row;
  margin: 0.2rem;
  border: 2px solid #d3d3d3;
  border-radius: 5px;
  overflow: hidden;
  width: 10.4rem;
}

.counter button {
  padding: 0.4rem 0.6rem;
  border: none;
  background-color: #fff;
  cursor: pointer;
}
/* .counter button:hover {
  outline: 2px solid #d3d3d3;
} */
.counter input {
  text-align: center;
  border: none;
  width: 7rem;
}

.counter input::-webkit-outer-spin-button,
.counter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.counter input[type='number'] {
  -moz-appearance: textfield;
}
